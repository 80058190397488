'use client'
import React, {useEffect, useState} from "react";
import styled from "styled-components";



const ScrollArrow = ({offset}) => {
    const handleScroll = () => {
        window.scrollBy({
            top: window.innerHeight, // Scroll by the full viewport height
            behavior: 'smooth', // Smooth scrolling
        });
    };
    return (
        <StyledArrow className='scroll-down-btn' onClick={handleScroll} offset={offset}>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <circle opacity="0.5" cx="21" cy="21" r="20" stroke="#F6F8FE"/>
                <path d="M21 13V29" stroke="#F6F8FE" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 23L21 29L27 23" stroke="#F6F8FE" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </StyledArrow>
    );
};

const StyledArrow = styled.section`
    position: absolute;
    bottom: 30px; /* Distance from the bottom */
    left: ${props => props.offset + 15}px;
    cursor: pointer;
    z-index: 9999999999999; /* Ensure it stays on top of other elements */

    svg {
        width: 50px; /* Adjust the size of the arrow */
        height: 50px;
        stroke: white; /* Arrow color */
        transition: transform 0.3s ease;
    }

    &:hover svg {
        transform: scale(1.1); /* Scale up the arrow on hover */
    }

    svg circle {
        stroke-opacity: 0.8; /* Circle opacity */
    }

    svg polyline, svg line {
        stroke-width: 2;
    }
`;


export default ScrollArrow;
